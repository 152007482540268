import { request } from './request'

class PayUAPI {
	constructor() {
		this.route = '/wp-json/payu/v1'
    }
    initPayment(orderId){
        const url = this.route + `/get_order/${orderId}`
        const config = {
            method:'GET'
        }
		return request(url, config)
    }
	getTransactionStatus(transactionId) {
        const url = this.route + `/get_payment_status/${transactionId}`
		const config = {
			method: 'GET'
		}
		return request(url, config)
	}
}

const PayUApi = new PayUAPI()

export default PayUApi
