import { request } from "./request"
import WC_GLOBALS from '../wp_globals/WOOCOMMERCE'
const { KEY, SECRET } = WC_GLOBALS.APIAUTH

class WPAPI {
  constructor() {
    this.route = "/wp-json/wc/v3"
  }

  getAllProducts() {
    const url = this.route + `/products`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }

    return request(url, config)
  }

  postCreateOrder(customer, cart, shipping, coupon={}) {
    const url = this.route + `/orders`
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        billing: customer,
        shipping: customer,
        customer_note: customer.note,
        line_items: cart,
        shipping_lines:[shipping],
        ...coupon
      },
    }

    return request(url, config)
  }

  changeOrder(id, data={status:'completed'}){
    const url = this.route + `/orders/${id}`
    const config = {
      method: "PUT",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data
    }

    return request(url, config)
  }

  searchByPaymentId(paymentId){
    const url = this.route + `/orders?search=${paymentId}`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      }
    }

    return request(url, config)
  }

  search(phrase=''){
    const urlProducts = this.route + `/products?search=${phrase}&per_page=100`
    const configProducts = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      }
    }
    const orders = () => request(urlProducts, configProducts)
    
    const urlPosts = `/wp-json/wp/v2/posts?search=${phrase}&per_page=100`
    const configPosts = {
      method: "GET"
    }
    const posts = () => request(urlPosts, configPosts)

    const urlPages = `/wp-json/wp/v2/pages?search=${phrase}&per_page=100`
    const configPages = {
      method: "GET"
    }
    const pages = () => request(urlPages, configPages)

    return Promise.all([
      orders(), posts(), pages()
    ])
  }

  sendMail(email, query={}){
    const url = "/wp-json/contact-form-7/v1/contact-forms/502/feedback"
    const data = new FormData()
    data.append('email', email)

    const config = {
      method: 'POST',
      query: query,
      multipart:true,
      data
    }
    return request(url, config)
  }

  sendNewsletter(email, query={}){
    const url = "/wp-json/contact-form-7/v1/contact-forms/502/feedback"

    const data = new FormData()
    data.append('email', email)

    const config = {
      method: 'POST',
      query: query,
      multipart:true,
      data
    }
    return request(url, config)
  }

  sendDirectPaymentNotification(customer, sum,  query={}){
    const url = "/wp-json/contact-form-7/v1/contact-forms/450/feedback"
    const data = new FormData()
    data.append('first_name', customer.first_name)
    data.append('last_name', customer.last_name)
    data.append('email', customer.email)
    data.append('phone', customer.phone)
    data.append('sum', sum)
    const config = {
      method: 'POST',
      query: query,
      multipart:true,
      data
    }
    return request(url, config)
  }

  sendPersonalCollectionNotification(customer, sum,  query={}){
    const url = "/wp-json/contact-form-7/v1/contact-forms/462/feedback"
    const data = new FormData()
    data.append('first_name', customer.first_name)
    data.append('last_name', customer.last_name)
    data.append('email', customer.email)
    data.append('phone', customer.phone)
    data.append('sum', sum)
    const config = {
      method: 'POST',
      query: query,
      multipart:true,
      data
    }
    return request(url, config)
  }
  
  sendBlikNotification(customer, sum, query={}){
    const url = "/wp-json/contact-form-7/v1/contact-forms/464/feedback"
    const data = new FormData()
    data.append('first_name', customer.first_name)
    data.append('last_name', customer.last_name)
    data.append('email', customer.email)
    data.append('phone', customer.phone)
    data.append('sum', sum)
    const config = {
      method: 'POST',
      query: query,
      multipart:true,
      data
    }
    return request(url, config)
  }

  getDeliveryOptions(){
    // POST /wp-json/wc/v3/shipping/zones
    // POST /wp-json/wc/v3/shipping/zones/<id>/methods
    const url = "/wp-json/wc/v3/shipping/zones"
    const config = {
      method: 'GET',
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      }
    }
    return request(url, config).then((data)=>{
      const [selected] = data.filter(el => el.name === 'Europa')
      const url = `/wp-json/wc/v3/shipping/zones/${selected.id}/methods`
      const config = {
        method: 'GET',
        withCredentials: true,
        auth: {
          username: KEY,
          password: SECRET,
        }
      }
      return request(url, config)
    })
  }

  loginCustomer(email, password){
    const url = `/wp-json/wp/v2/users/login`
    const config = {
      method: 'POST',
      data: {
        email, password
      },
    }
    return request(url, config).then(({id})=>{
      const url = `/wp-json/wc/v3/customers/${id}`
      const config = {
        method: 'GET',
        withCredentials: true,
        auth: {
          username: KEY,
          password: SECRET,
        }
      }
      return request(url, config)
    })
  }
  createCustomer(customer){
    const url = '/wp-json/wc/v3/customers'
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        email: customer.email,
        first_name: customer.first_name,
        last_name: customer.last_name,
        password: customer.password,
        username: customer.email,
        billing: customer,
        shipping: customer,
      },
  }

  return request(url, config)
}
updateCustomer(customer, id){
  const url = `/wp-json/wc/v3/customers/${id}`
  const config = {
    method: "PUT",
    withCredentials: true,
    auth: {
      username: KEY,
      password: SECRET,
    },
    data: {
      email: customer.email,
      first_name: customer.first_name,
      last_name: customer.last_name,
      password: customer.password,
      username: customer.username,
      billing: customer,
      shipping: customer,
    },
  }
  return request(url, config)
}
}

const WPApi = new WPAPI()

export default WPApi
