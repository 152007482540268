import React from 'react'
import { Provider } from 'react-redux'
import store, { setSearchData, onSearchError } from '@stores'
import { onAppInit, onInitSearch } from './app.logic'

import './styles/constants.scss'
import './styles/global.scss'
import './styles/mixins.scss'
import './styles/anim.scss'

const WindowCtx = React.createContext()

class Wrapper extends React.Component {
	state = {
		window: {
			height: 0,
			width: 0,
		},
		scroll: { x: 0, y: 0 },
	}

	componentWillMount() {
		onAppInit(store)
		onInitSearch()
			.then((data) => {
				store.dispatch(setSearchData(data))
			})
			.catch((err) => {
				store.dispatch(onSearchError(err))
			})
	}

	componentDidMount() {
		this.setState({
			window: {
				height: window.innerHeight,
				width: window.innerWidth,
			},
			browser: {
				height: window.outerHeight,
				width: window.outerWidth,
			},
			scroll: {
				y: window.scrollY,
				x: window.scrollX,
			},
		})

		window.onscroll = () => {
			this.setState({
				scroll: {
					y: window.scrollY,
					x: window.scrollX,
				},
			})
		}

		window.onresize = () => {
			this.setState({
				window: {
					height: window.innerHeight,
					width: window.innerWidth,
				},
			})
		}
	}

	render() {
		return (
			<WindowCtx.Provider value={this.state}>
				{this.props.children}
			</WindowCtx.Provider>
		)
	}
}

export default ({ element }) => (
		<Wrapper>
      <Provider store={store}>{element}</Provider>
		</Wrapper>
)

export { WindowCtx }
