import { WPApi } from './api'
import {
	loadState,
	loadCart,
	loadCustomer,
	setShippings
} from './state'

function onInitSearch() {

	return WPApi.search().then(([products, posts, pages]) => {
		const _posts = posts.map((el) => {
			return {
				id: el.id,
				title: el.title.rendered,
				type: 'post',
				slug: el.slug,
				content: el.content.rendered,
			}
		})
		const _products = products.map((el) => {
			return {
				id: el.id,
				title: el.name,
				slug: el.slug,
				type: 'product',
				content: el.description + ' ' + el.shortDescription,
				image:el.images[0] ? el.images[0].src : null
			}
		})

		const _pages = pages.map((el) => {
			return {
				id: el.id,
				title: el.title.rendered,
				slug: el.slug,
				type: 'page',
				content: el.content.rendered,
			}
		})
		return {
			products: _products,
			posts: _posts,
			pages: _pages,
		}
	})
}

function onAppInit(store){
	const persistantState = loadState()
    if (persistantState) {
        store.dispatch(loadCart(persistantState.Cart))
        store.dispatch(loadCustomer(persistantState.Customer))
	}
	
	return WPApi.getDeliveryOptions().then((data)=>{
		const [ rangeObject ] = data.filter((el)=>Object.keys(el.settings).includes('min_amount'))
		const notFreeShippings = data.filter((el)=>!Object.keys(el.settings).includes('min_amount'))
		const freeShippings = data.filter((el)=>el.method_id.includes('free'))
		const {min_amount} = rangeObject.settings
		const shippings = {
			"always": notFreeShippings.map((el)=>{
				return {
					method_id: el.method_id,
					method_title: el.title,
					total: Number(el.settings.cost.value),
				}
			}),
			[min_amount.value]: notFreeShippings.map((el)=>{
				return {
					method_id: el.method_id,
					method_title: el.title,
					total: Number(el.settings.cost.value),
				}
			}),
			"free": freeShippings.map((el)=>{
				return {
					method_id: el.method_id,
					method_title: el.title,
					total: 0
				}
			})
		}
		store.dispatch(setShippings(shippings))
	})
}

export { onAppInit, onInitSearch }