// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-post-js": () => import("./../../../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */),
  "component---src-prepages-blog-js": () => import("./../../../src/prepages/blog.js" /* webpackChunkName: "component---src-prepages-blog-js" */),
  "component---src-prepages-categories-js": () => import("./../../../src/prepages/categories.js" /* webpackChunkName: "component---src-prepages-categories-js" */),
  "component---src-prepages-checkout-js": () => import("./../../../src/prepages/checkout.js" /* webpackChunkName: "component---src-prepages-checkout-js" */),
  "component---src-prepages-home-js": () => import("./../../../src/prepages/home.js" /* webpackChunkName: "component---src-prepages-home-js" */),
  "component---src-prepages-product-js": () => import("./../../../src/prepages/product.js" /* webpackChunkName: "component---src-prepages-product-js" */),
  "component---src-prepages-products-js": () => import("./../../../src/prepages/products.js" /* webpackChunkName: "component---src-prepages-products-js" */),
  "component---src-prepages-shop-js": () => import("./../../../src/prepages/shop.js" /* webpackChunkName: "component---src-prepages-shop-js" */),
  "component---src-prepages-summary-js": () => import("./../../../src/prepages/summary.js" /* webpackChunkName: "component---src-prepages-summary-js" */)
}

